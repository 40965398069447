.categoryCardColored_contGen {
  width: 30%;
  height: 500px;
  border-radius: 30px;
  background: linear-gradient(180deg, #85c04e 0%, #3c3d73 100%);
  padding: 30px;
  /* display: flex;
  align-items: center;
  flex-direction: column; */
  box-sizing: border-box;
  filter: drop-shadow(0px 10px 15px rgba(151, 151, 151, 0.08));
}

.categoryCardColored_ImgCont {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
}

.categoryCardColored_ImgCont img {
  margin-left: auto;
  margin-right: auto;
}

.categoryCardColored_textHead {
  color: #fff;
  /* text-align: center; */
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 30px;
}

.categoryCardColored_textPara {
  color: #fff;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin-top: 20px;
}

@media screen and (max-width: 992px) {
  .categoryCardColored_contGen {
    width: 90%;
    margin-bottom: 40px;
    height: fit-content;
  }
}
