@import url("https://fonts.googleapis.com/css2?family=Abhaya+Libre:wght@400;500;600;700;800&family=Montserrat:wght@400;500;600;700;800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,400&display=swap");

body {
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0;
  background-color: #ffffff;
  overflow-x: hidden;
}

.header_rightGen {
  background: #a6f061;
  height: 50px;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 20px;
  padding-right: 20px;
  cursor: pointer;
  transition: 0.3s ease-in-out 0s;
}

.header_rightGen:hover {
  background: #4c9805;
}
.header_rightGen_icon {
  font-size: 14px;
  color: #3c3d73;
  margin-right: 10px;
}

.header_rightGenDetail {
  font-size: 11.5px;
  color: #3c3d73;
  font-weight: 500;
  font-style: normal;
}
