.immuneBooster_sectionOneCont {
  width: 100%;
  height: 100%;

  top: 0;

  /* background: rgba(0, 0, 0, 0.8); */
  /* background: #f4feff; */
  background: #fff;
}
.immuneBooster_sectionOne_gen {
  max-width: 1200px;
  display: flex;
  align-items: center;
  gap: 40px;
  flex-wrap: wrap;
  /* background: #f6ffec; */
  justify-content: flex-start;
  /* flex-direction: column; */
  padding-top: 100px;
  padding-bottom: 150px;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
  /* text-align: center; */
  margin-left: auto;
  margin-right: auto;
  position: relative;
  width: 100%;
}

@media screen and (max-width: 992px) {
  .immuneBooster_sectionOne_gen {
    justify-content: center;
    padding-top: 190px;
  }
}
