.contactUs_sectionOneCont {
  width: 100%;
  height: 100%;

  top: 0;

  /* background: rgba(0, 0, 0, 0.8); */
  /* background: #f4feff; */
  background: #fff;
}
.contactUs_sectionOne_gen {
  max-width: 1200px;
  display: flex;
  align-items: flex-start;
  /* background: #f6ffec; */
  justify-content: space-between;
  /* flex-direction: column; */
  padding-top: 100px;
  padding-bottom: 150px;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
  /* text-align: center; */
  margin-left: auto;
  margin-right: auto;

  width: 100%;
}

.contact_genLeftCont {
  width: 50%;
  /* height: 700px; */
  background: rgba(33, 133, 252);
  border-radius: 30px 0px 0px 30px;
  padding: 40px;
  box-sizing: border-box;
}

.contact_genRightCont {
  width: 50%;
  height: 500px;
  padding: 40px;
  box-sizing: border-box;
  /* background: #fff;
  filter: drop-shadow(0px 10px 15px rgba(151, 151, 151, 0.08)); */
  /* height: 700px; */
  /* border-radius: 0px 30px 30px 0px; */

  /* justify-content: flex-start; */
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.contact_genLeftContHeadText {
  color: #fff;
  font-family: Poppins;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 10px;
  margin-bottom: 40px;
}

.contact_genLeftContHeadText_span {
  color: #fff;
  font-family: Poppins;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.contact_genLeftCont_input {
  width: 100%;
  height: 50px;
  margin-top: 20px;
}

.contact_genLeftCont_input input {
  border: none;
  border-bottom: 1px solid #fff;
  outline: none;
  background: transparent;
  color: #fff;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
}

.contact_genLeftCont_input ::placeholder {
  color: #fff;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.contact_genLeftCont_textarea {
  width: 100%;
  height: 50px;
  margin-top: 20px;
}

.contact_genLeftCont_textarea textarea {
  border: none;
  border-bottom: 1px solid #fff;
  outline: none;
  background: transparent;
  color: #fff;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
}

.contact_genLeftCont_textarea ::placeholder {
  color: #fff;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.contact_genLeftCont_BtnSub {
  border-radius: 39px;
  background: linear-gradient(127deg, #fab800 0%, #2185fc 100%);

  /* w */
  box-shadow: 0px 10px 15px 0px rgba(1, 115, 153, 0.2);
  padding: 12px 30px;

  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;

  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  cursor: pointer;
  transition: 0.3s ease-in-out 0s;
  border: none;
  outline: none;
  margin-top: 100px;
}
.contact_genLeftCont_BtnSub:hover {
  box-shadow: none;
  transform: scale(1.05);
  /* background: linear-gradient(127deg, #3c3d73 0%, #85c04e 100%); */
}

.contact_genRightCont_ {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.contact_genRightCont_Img {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
}

.contact_genRightCont_ImgText {
  color: #3c3d73;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  word-wrap: break-word;
}

.contact_social_accts {
  display: flex;
  align-items: center;
  margin-top: 70px;
}

.contact_social_each {
  margin-right: 15px;
  cursor: pointer;
  transition: 0.3s ease-in-out 0s;
}

.contact_social_each:hover {
  transform: scale(1.5);
}

@media screen and (max-width: 992px) {
  .contactUs_sectionOne_gen {
    flex-direction: column;
    padding-top: 150px;
    align-items: center;
  }

  .contact_genLeftCont {
    width: 90%;
    border-radius: 30px;
  }

  .contact_genRightCont {
    width: 90%;
  }
}
