@import url("https://fonts.googleapis.com/css2?family=Abhaya+Libre:wght@400;500;600;700;800&family=Montserrat:wght@400;500;600;700;800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,400&display=swap");

body {
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0;
  background-color: #ffffff;
  overflow-x: hidden;
}

.headerFirstTopBorder {
  /* background: #85c04e; */

  background: linear-gradient(99deg, #fab800 0%, #2185fc 100%);

  width: 100%;
  height: 5px;
}

.headerSecondJust {
  background: #000;
  width: 100%;
  height: 50px;
}
.headerSecondJustMax {
  max-width: 1200px;
  margin-right: auto;
  margin-left: auto;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.headerTopFlex {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
}

.headerTopRight_textCont {
  display: flex;
  align-items: center;
}
.headerTopRight_text {
  color: #ffffff;
  font-size: 12px;
  font-weight: 500px;
  font-style: normal;
  margin-right: 10px;
  cursor: pointer;
  transition: 0.3s ease-in-out 0s;
}

.headerTopRight_text:hover {
  color: #f26a3c;
}

.borderRight {
  background: #f26a3c;
  width: 2px;
}

.mainHeader_logo img {
  width: 100px;
  height: 45px;
}

.mainHeaderOpt_Gen {
  max-width: 1200px;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  background-color: #ffffff;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  transition: 0.3s ease-in-out 0s;
}
.mainHeaderOpt_GenScroll {
  max-width: 1200px;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;

  background: transparent;
}

.mainHeaderOpt_GenMax {
  width: 100%;

  background-color: #ffffff;
  /* box-shadow: 0 4px 2px -2px #003399; */
  margin-top: -10px;
  transition: 0.3s ease-in-out 0s;
}
.mainHeaderOpt_GenMaxScroll {
  width: 100%;
  position: fixed;

  background: rgba(23, 25, 35, 0.2);
  backdrop-filter: blur(30px);
  z-index: 100;
  margin-top: -70px;
}

.mainHeader_rightFlex {
  display: flex;
  align-items: center;
}

.mainHeader_rightOption {
  color: #2185fc;
  /* border: 1.5px solid #3c3d73; */
  border-radius: 25px;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  margin-right: 10px;
  cursor: pointer;
  width: fit-content;
  /* padding: 8px 15px; */
  padding-left: 15px;
  padding-right: 15px;
  height: 40px;
  transition: 0.3s ease-in-out 0s;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}

.mainHeader_rightOptSub {
  color: #3c3d73;
  border: 1.5px solid #3c3d73;
  border-radius: 12px;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  margin-right: 10px;
  cursor: pointer;
  transition: 0.3s ease-in-out 0s;
  padding-left: 15px;
  padding-right: 15px;
  height: 40px;

  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}

.mainHeader_rightOptSub:hover {
  background: #3c3d73;
  color: #ffffff;
}

.mainHeader_rightOptionActive {
  /* background: #3c3d73; */
  color: #2185fc;
  /* border-bottom: 1.5px solid #858282; */
  box-shadow: 0px 10px 15px 0px rgba(1, 115, 153, 0.2);
  border-radius: 25px;

  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  margin-right: 10px;
  cursor: pointer;
  width: fit-content;
  /* padding: 8px 15px; */
  padding-left: 15px;
  padding-right: 15px;
  height: 40px;
  transition: 0.3s ease-in-out 0s;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}

/* .mainHeader_rightOptionActivess {
  color: #000;
  box-shadow: 0px 10px 15px 0px rgba(1, 115, 153, 0.2);
  border-radius: 12px;

  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  margin-right: 10px;
  cursor: pointer;
  width: fit-content;
  padding-left: 15px;
  padding-right: 15px;
  height: 40px;
  transition: 0.3s ease-in-out 0s;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
} */

.mainHeader_rightOption:hover {
  /* background: #3c3d73; */
  /* border-bottom: 1.5px solid #858282; */
  box-shadow: 0px 10px 15px 0px rgba(1, 115, 153, 0.2);
  /* color: #ffffff; */
}

.mainheader_optSubsIcon {
  margin-top: 3px;
  margin-left: 5px;
}

.subMenu_header {
  display: none;
  /* position: absolute; */
}
.general_dropDown {
  position: relative;
}

.general_dropDown:hover .subMenu_header {
  display: block;
}

/* .subMenu_header:hover {
  display: block;
} */

.mobilenavbar-optMenulogo img {
  width: 100px;
  height: 50px;
  margin-left: 40px;
  margin-top: 15px;
}

.products_famGen {
  border-radius: 39px;
  background: linear-gradient(127deg, #fab800 0%, #2185fc 100%);

  /* w */
  box-shadow: 0px 10px 15px 0px rgba(1, 115, 153, 0.2);
  padding: 10px 25px;

  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;

  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  cursor: pointer;
  transition: 0.3s ease-in-out 0s;
}
.products_famGen:hover {
  box-shadow: none;
  transform: scale(1.05);
  /* background: linear-gradient(127deg, #3c3d73 0%, #85c04e 100%); */
}

.discount_textColor {
  background: linear-gradient(99deg, #fab800 0%, #2185fc 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-right: 40px;
}

.discount_btn_find {
  border-radius: 39px;
  background: linear-gradient(99deg, #fab800 0%, #2185fc 100%);

  /* w */
  box-shadow: 0px 10px 15px 0px rgba(1, 115, 153, 0.2);
  padding: 8px 20px;

  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;

  font-family: Poppins;
  font-size: 8px;
  font-style: normal;
  font-weight: 600;
  cursor: pointer;
  transition: 0.3s ease-in-out 0s;
}

.discount_btn_find:hover {
  box-shadow: none;
  /* background: linear-gradient(127deg, #3c3d73 0%, #85c04e 100%); */
}

@media screen and (max-width: 992px) {
  .firstBanner-maxGen {
  }

  .mobilenavbar-optMenuFam {
    position: fixed;
    background: #ffffff;
    width: 100%;
    z-index: 9000000000;
    margin-top: -25px;
  }
}
