.medCategory_genParent {
  width: 250px;
  height: 400px;
  border-radius: 30px;
  background: linear-gradient(180deg, #85c04e 0%, #3c3d73 100%);
  padding: 20px;
  /* display: flex;
  align-items: center;
  flex-direction: column; */
  box-sizing: border-box;
  filter: drop-shadow(0px 10px 15px rgba(151, 151, 151, 0.08));
  position: relative;
  transition: 0.3s ease-in-out 0s;
  cursor: pointer;
}

.medCategory_genParent:hover {
  transform: scale(1.05);
}

.medcategoryCardColored_ImgCont {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.medcategoryCardColored_ImgCont img {
  margin-left: auto;
  margin-right: auto;
}

.medcategoryCardColored_textHead {
  color: #fff;
  /* text-align: center; */
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 10px;
}

.medcategoryCardColored_textPara {
  color: #172048;
  background: #fff;
  width: fit-content;
  padding: 10px 20px;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  margin-top: 20px;
  position: absolute;

  bottom: 40px;
  left: -2px;
  border-radius: 0px 30px 30px 0px;

  /* background: linear-gradient(99deg, #85c04e 0%, #3c3d73 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
}

@media screen and (max-width: 992px) {
  /* .medCategory_genParent {
    width: 90%;
  } */
}
