.allProducts_sectionOneCont {
  width: 100%;
  height: 100%;

  top: 0;

  /* background: rgba(0, 0, 0, 0.8); */
  /* background: #f4feff; */
  background: #fff;
}
.allProducts_sectionOne_gen {
  max-width: 1200px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 50px;
  /* gap: 10; */
  flex-wrap: wrap;
  /* flex-direction: column; */
  padding-top: 150px;
  padding-bottom: 50px;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
  /* text-align: center; */
  margin-left: auto;
  margin-right: auto;

  width: 100%;
}

.whatsappStickbody {
  position: fixed;
  right: 5%;
  bottom: 50px;
  background: green;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  color: #fff;
  font-size: 35px;
  z-index: 2000000000;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.whatsappStickActive {
  position: absolute;
  top: 0;
  left: 0;
  width: 15px;
  height: 15px;
  background: red;
  border-radius: 50%;
}

.whatsappStickbody img {
  width: 100%;
  height: 100%;
}
@media screen and (max-width: 992px) {
  .allProducts_sectionOne_gen {
    justify-content: center;
  }
}
